<template>
    <div id="container"></div>
</template>
<script type="text/javascript">
export default {
    data: () => ({
        map: null,
        markers: [],
        infoWindowInstance: null // 信息窗体单例，因为信息窗体不会出现两个
    }),
    props: {
        points: Array
    },
    watch: {
        points(newPoints) {
            if (this.map !== null) {
                this.markers.forEach((marker) => {
                    marker.remove()
                })
                this.markers = []
                this.addSawPointToMarkers(newPoints)
            }
        }
    },
    mounted() {
        // 原地刷新时稍微等待地图组件加载完成
        if (window.AMap === undefined) {
            const waitId = setInterval(() => {
                if (window.AMap !== undefined) {
                    clearInterval(waitId)
                    this.initMap()
                }
            }, 1000)
        } else {
            this.initMap()
        }
    },
    methods: {
        initMap() {
            this.map = new window.AMap.Map('container', {
                center: new window.AMap.LngLat(120.655245, 27.778967),
                zoom: 15,
                animateEnable: false
            })
            this.infoWindowInstance = new window.AMap.InfoWindow({
                anchor: 'top-right'
            })
            // 动态绘制marker
            this.map.on('touchend', this.markersFix)
            this.addSawPointToMarkers(this.points)
        },
        // 这个函数先清除不在视野内的marker，再调用addSawPointToMarkers添加视野内未添加point
        markersFix() {
            const bounds = this.map.getBounds()
            // 如果marker不在视野内，则去除
            this.markers.forEach((marker) => {
                if (!bounds.contains(marker.getPosition())) {
                    marker.setExtData({ ...marker.getExtData(), removed: true })
                    marker.remove()
                }
            })
            this.markers = this.markers.filter((marker) => !marker.getExtData().removed)
            // 将视野内且未添加的point转成marker加入地图
            this.addSawPointToMarkers(this.points)
        },
        addSawPointToMarkers(points) {
            points
                // 去除没有坐标的
                .filter((point) => point.lat && point.lng)
                // 去除不在视野内的
                .filter((point) => {
                    const bounds = this.map.getBounds()
                    return bounds.contains(new window.AMap.LngLat(point.lng, point.lat))
                })
                // 去除已经渲染出来的，因为渲染出的marker应该远少于point，所以暂时直接搜索
                .filter((point) => {
                    for (const marker of this.markers) {
                        if (point.lng === marker.getPosition().getLng() && point.lat === marker.getPosition().getLat()) {
                            return false
                        }
                    }
                    return true
                })
                .forEach((point) => {
                    this.markers.push(this.pointToMarker(point))
                })
        },
        pointToMarker(point) {
            let marker = new window.AMap.Marker({
                map: this.map,
                position: new window.AMap.LngLat(point.lng, point.lat),
                extData: { removed: false }
            })
            marker.on('click', () => {
                let timeContent = ''
                if (point.status === '施工中') {
                    timeContent = `至${this.dateFormat(point.complete_date)}<span class="red">${point.more}</span>`
                }
                const content = [
                    `<p>${point.location}</p>`,
                    `<p>申请单位：${point.submit_unit}</p>`,
                    `<p>巡查负责：${point.rematch_user}</p>`,
                    `<p>挖掘时间：${timeContent}</p>`,
                    `<p><a class="blue" href="/#/detail?id=${point.id}">查看详情</a><p>`
                ]
                this.infoWindowInstance.setContent(content.join(''))
                this.infoWindowInstance.open(this.map, marker.getPosition())
            })
            return marker
        }
    }
}
</script>
<style lang="less" scoped>
#container {
    padding: 0px;
    margin: 0px;
    width: 100%;
}
</style>
<style>
.amap-logo,
.amap-copyright {
    z-index: -1 !important;
}
</style>
