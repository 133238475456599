<template>
    <div class="bg-slightGrey">
        <!-- 顶部tab栏 -->
        <template v-if="tabShow">
            <!-- 审核者身份-->
            <div v-if="identity !== IDENTITY_MATCHER" class="width-90 paddingLr90 bg-white flex-row justify-content-spaceBetween align-items-center">
                <div
                    v-for="(item, index) in tabConfig"
                    :key="index"
                    @click="switchTab(index)"
                    class="flex-column justify-content-center align-items-center width-30 margin-t"
                >
                    <p v-if="index === 0" class="lineHeight fs-mid" :class="{ 'fs-big fw-mid blue': index === tabNum }">
                        {{ item.text }}
                    </p>
                    <van-badge v-if="index === 2 || index === 1" :content="item.count" max="99">
                        <p class="lineHeight fs-mid" :class="{ 'fs-big fw-mid blue': index === tabNum }">
                            {{ item.text }}
                        </p>
                    </van-badge>
                    <img
                        width="28px"
                        :class="index === tabNum ? 'opacity-8' : 'opacity-0'"
                        src="@/assets/image/icon13.png"
                        alt=""
                        class="margin-bs"
                    />
                </div>
            </div>
            <!-- 巡查者身份 -->
            <div v-else class="width-90 paddingLr90 bg-white">
                <div class="flex-column justify-content-center align-items-center width-30 margin-t">
                    <p class="fs-big blue fw-mid lineHeight white-space-nowrap">我负责的施工</p>
                    <img width="28px" src="@/assets/image/icon13.png" alt="" class="margin-bs" />
                </div>
            </div>
        </template>
        <!-- 改为导航栏 -->
        <template v-else>
            <van-nav-bar :title="navTitle" />
        </template>
        <div class="width-100 van-dropdown-menu">
            <van-dropdown-menu active-color="#007AFF">
                <!-- 秩序中队、大队的“片区”选项 -->
                <van-dropdown-item
                    v-if="isGlobal"
                    v-model="paramsProjectArea"
                    :options="paramsProjectAreaOptions"
                    @change="handleProjectAreaChange"
                />
                <van-dropdown-item v-model="paramsProjectStatus" :options="paramsProjectStatusOptions" @change="handleProjectStatusChange" />
                <van-dropdown-item :title="startRangeTitle" ref="startRangeDrop" @open="RangeShow = true" @close="RangeShow = false">
                    <van-calendar
                        v-model="RangeShow"
                        type="range"
                        position="bottom"
                        @confirm="handleStartRangePick"
                        @close="
                            $refs.startRangeDrop.toggle(false)
                            RangeShow = $refs.startRangeDrop.showPopup
                        "
                        color="#1989fa"
                        :min-date="new Date('2021/1/1')"
                    >
                        <template slot="title">
                            <van-button type="danger" round class="clear-range-button" @click="handleStartRangeClear">清除选择</van-button>
                        </template>
                    </van-calendar>
                </van-dropdown-item>
                <van-dropdown-item :title="completeRangeTitle" ref="completeRangeDrop" @open="RangeShow = true" @close="RangeShow = false">
                    <van-calendar
                        v-model="RangeShow"
                        type="range"
                        position="bottom"
                        @confirm="handleCompleteRangePick"
                        @close="
                            $refs.completeRangeDrop.toggle(false)
                            RangeShow = $refs.completeRangeDrop.showPopup
                        "
                        color="#1989fa"
                        :min-date="new Date('2021/1/1')"
                    >
                        <template slot="title">
                            <van-button type="danger" round class="clear-range-button" @click="handleCompleteRangeClear">清除选择</van-button>
                        </template>
                    </van-calendar>
                </van-dropdown-item>
            </van-dropdown-menu>
        </div>
        <div
            v-show="!showMap"
            class="width-90 paddingLr90"
            style="overflow-y: scroll; padding-bottom: 60px"
            :style="{ height: `${innerHeight - 168 - 60}px` }"
        >
            <van-pull-refresh class="minHeight" v-model="isLoading" @refresh="onRefresh">
                <!-- 列表start -->
                <div
                    v-for="item in list"
                    :key="item.id"
                    @click="jumpto('/detail', { id: item.id })"
                    class="br-reg border van-overflow-hidden bg-white width-100 margin-t"
                >
                    <div
                        :class="['titBg-red', 'titBg-green', 'titBg-blue'][item.stepNum]"
                        class="titBg margin-bs flex-row justify-content-spaceBetween align-items-center"
                    >
                        <div class="width-75 flex-row justify-content-start align-items-center">
                            <img v-if="item.stepNum === 1" width="24px" height="24px" src="@/assets/image/icon2.png" alt="" />
                            <img v-if="item.stepNum === 2" width="24px" height="24px" src="@/assets/image/icon3.png" alt="" />
                            <img v-if="item.stepNum === 0" width="24px" height="24px" src="@/assets/image/icon6.png" alt="" />
                            <p class="fs-big width-80 cutText black fw-mid margin-ls">{{ item.location }}</p>
                        </div>
                        <p :class="['red', 'green', 'blue'][item.stepNum]" class="fs-sml width-20 textAlign-r red">
                            {{ item.status }}
                        </p>
                    </div>
                    <div class="width-90 fs-sml grey paddingLr90 margin-bs flex-row justify-content-spaceBetween align-items-start">
                        <p class="width-40">申请单位：</p>
                        <p class="width-60 textAlign-r black cutText">{{ item.submit_unit }}</p>
                    </div>
                    <div class="width-90 fs-sml grey paddingLr90 margin-bs flex-row justify-content-spaceBetween align-items-start">
                        <p class="">挖掘时间：</p>
                        <p v-if="item.status === '施工中'" class="textAlign-r black">
                            至{{ dateFormat(item.complete_date) }} <span class="red cutText">{{ item.more }}</span>
                        </p>
                        <p v-if="item.status === '申请中'" class="textAlign-r black">{{ dateFormat(item.start_date) }}开始</p>
                        <p v-if="item.status === '施工完成'" class="textAlign-r black">
                            {{ dateFormat(item.start_date) }}到{{ dateFormat(item.complete_date) }}
                        </p>
                    </div>
                    <div
                        v-if="item.status === '施工中'"
                        class="width-90 fs-sml grey paddingLr90 margin-bs flex-row justify-content-spaceBetween align-items-center"
                    >
                        <p class="width-40">已巡查次数：</p>
                        <p class="width-60 textAlign-r black cutText">{{ item.rematch_count }}次</p>
                    </div>
                    <div
                        v-if="item.last_rematch_time"
                        class="width-90 fs-sml grey paddingLr90 margin-bs flex-row justify-content-spaceBetween align-items-center"
                    >
                        <p class="width-40">上一次巡查日期：</p>
                        <p class="width-60 textAlign-r black cutText">{{ datetimeFormat(item.last_rematch_time) }}</p>
                    </div>
                </div>
                <!--          列表end-->
                <van-empty v-if="list.length === 0" description="暂时没有内容哦~" />
            </van-pull-refresh>
        </div>
        <AMap v-show="showMap" :points="list" ref="mapCom"></AMap>
        <van-button round :color="showMapButtonContent[showMap].color" class="map-show-change-button" @click="handleShowMapChange">{{
            showMapButtonContent[showMap].text
        }}</van-button>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import AMap from '@/components/AMap.vue'
export default {
    name: '',
    components: { AMap },
    data() {
        return {
            isLoading: false,
            showMap: false,
            showMapButtonContent: {
                true: {
                    color: '#007aff',
                    text: '显示列表'
                },
                false: {
                    color: '#ff3b30',
                    text: '显示地图'
                }
            },

            tabShow: true,
            navTitle: '',
            tabNum: 0,
            tabConfig: [
                {
                    value: 'all',
                    text: '全部',
                    params: {
                        status: null,
                        information__complete_date__lt: null
                    }
                },
                {
                    value: 'building',
                    text: '施工中',
                    params: {
                        status: '施工中',
                        information__complete_date__lt: null
                    },
                    count: 0
                },
                {
                    value: 'overtime',
                    text: '已到期',
                    params: {
                        status: '施工中',
                        information__complete_date__lt: 'today' // 这项在createed中填充
                    },
                    count: 0
                }
            ],

            paramsProjectStatus: 0,
            paramsProjectStatusOptions: [
                { text: '全部', value: 0 },
                { text: '申请中', value: 1 },
                { text: '施工中', value: 2 },
                { text: '施工完成', value: 3 }
            ],

            paramsProjectArea: null,
            paramsProjectAreaOptions: [],

            RangeShow: true,
            startRangeTitle: '开始日期',
            completeRange: false,
            completeRangeTitle: '完成日期',

            url: '',
            params: {},
            list: [],
            pageCount: 0,

            identity: null,
            isGlobal: null
        }
    },
    created() {
        // 让底部活跃tab转移到施工
        this.$store.state.tabbarIndex = this.TAB_PROJECT
        // 初始化上方tab中的数量
        axios
            .get('/api/dd_user/user_index/')
            .then((res) => {
                const projectCount = res.data.project_count
                this.tabConfig.find((config) => config.value === 'building').count = projectCount.building
                let overtimeTabConfig = this.tabConfig.find((config) => config.value === 'overtime')
                overtimeTabConfig.count = projectCount.overtime
                // 初始化tabConfig中的日期
                overtimeTabConfig.params.information__complete_date__lt = this.dateFormat(new Date().getTime() / 1000, 'YYYY-M-D')
            })
            .catch((err) => {
                axios.post('/api/log/', {
                    level: 40,
                    content: {
                        context: '/api/dd_user/user_index/',
                        err: err
                    }
                })
                this.$dialog.alert({
                    message: '获取信息失败。请检查网络或重新登录。'
                })
            })
        // 识别到秩序中队或大队再填充
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        this.identity = userInfo.identity
        this.isGlobal = userInfo.is_global
        if (this.isGlobal) {
            axios.get('/api/supervision/area/').then((res) => {
                this.paramsProjectAreaOptions = res.data.map((area) => ({ value: area.id, text: area.text }))
                this.paramsProjectAreaOptions.find((area) => area.text === '全市').value = null
            })
        } else {
            this.params.information__area = userInfo.area
        }
        // 初始化请求参数
        const { pre_filter } = this.$route.query
        this.url = '/api/supervision/project_list/'
        if (this.identity === this.IDENTITY_MATCHER) {
            this.params.rematch_user = userInfo.dd_user_id
        }
        if (pre_filter === 'applying') {
            this.paramsProjectStatus = 1
            this.params.status = '申请中'
        } else if (pre_filter === 'building') {
            this.tabNum = 1
            this.params.status = '施工中'
        } else if (pre_filter === 'overtime') {
            // this.tabNum = 2
            this.switchTab(2)
            this.params.information__complete_date__lt = moment().format('YYYY-M-D')
        } else if (pre_filter === 'limit') {
            this.params.information__complete_date__lt = moment().add(7, 'days').format('YYYY-M-D')
            this.params.status = '施工中'
            this.params.ordering = 'information__complete_date'
            this.tabShow = false
            this.navTitle = '临近或超出预期的施工'
        } else if (pre_filter === 'waiting') {
            this.url = '/api/supervision/limit_list'
            this.tabShow = false
            this.navTitle = '待巡查的施工'
        } else if (pre_filter === 'id__in') {
            const { ids } = this.$route.query
            this.params.id__in = JSON.parse(ids).join(',')
            this.tabShow = false
            this.navTitle = '相关施工'
        }
        this.getData()
    },
    mounted() {},
    methods: {
        getData() {
            axios
                .get(this.url, {
                    params: this.params
                })
                .then((res) => {
                    this.list = res.data.results
                    for (let project of this.list) {
                        const msOfHour = 60 * 60 * 1000
                        const msOfDay = 24 * msOfHour
                        const now = Math.floor(Date.now() / msOfDay) * msOfDay - 8 * msOfHour // 处理时间偏移
                        const ms = project.complete_date * 1000 - now
                        const day = moment.duration(ms).asDays()
                        // 0：超期（红） 1：施工中（绿） 2：审核中（蓝）
                        if (project.status === '申请中') {
                            project.stepNum = 2
                        } else {
                            if (day < 0) {
                                project.more = `超出${-day}天`
                                project.step = '超出期限'
                                project.stepNum = 0
                            } else {
                                if (day > 0) {
                                    project.more = `剩余${day}天`
                                    project.step = '临近期限'
                                } else {
                                    project.more = '今日到期'
                                    project.step = '今日到期'
                                }
                                project.stepNum = 1
                            }
                        }
                    }
                    this.pageCount = res.data.count
                })
                .catch(() => {
                    this.$dialog.alert({
                        message: '无法获取数据，请检查网络后重新打开。'
                    })
                })
        },
        handleProjectStatusChange(value) {
            this.tabNum = 0
            this.params.information__complete_date__lt = null

            this.params.status = [null, '申请中', '施工中', '施工完成'][value]
            this.getData()
        },
        handleStartRangePick(dates) {
            this.implRangePick(dates, this.$refs.startRangeDrop, 'information__start_date__range', 'startRangeTitle')
            this.getData()
        },
        handleStartRangeClear() {
            this.$refs.startRangeDrop.toggle()
            this.params.information__start_date__range = null
            this.startRangeTitle = '开始日期'
            this.getData()
        },
        handleCompleteRangePick(dates) {
            this.implRangePick(dates, this.$refs.completeRangeDrop, 'information__complete_date__range', 'completeRangeTitle')
            this.getData()
        },
        handleCompleteRangeClear() {
            this.$refs.completeRangeDrop.toggle()
            this.params.information__complete_date__range = null
            this.completeRangeTitle = '开始日期'
            this.getData()
        },
        handleProjectAreaChange(area) {
            this.params.information__area = area
            this.getData()
        },
        handleShowMapChange() {
            this.showMap = !this.showMap
            setTimeout(this.$refs.mapCom.markersFix, 100)
        },
        onRefresh() {
            this.getData()
            setTimeout(() => {
                this.$toast('刷新成功')
                this.isLoading = false
            }, 1000)
        },
        switchTab(index) {
            this.paramsProjectStatus = 0
            this.tabNum = index
            const item = this.tabConfig[index]
            for (const param in item.params) {
                this.params[param] = item.params[param]
            }
            this.getData()
        }
    }
}
</script>

<style lang="less" scoped>
.van-dropdown-menu {
    height: 48px;
    overflow: hidden;
}
.van-button {
    height: 36px;
}
.clear-range-button {
    width: 50%;
}
.titBg {
    width: 90%;
    padding: 10px 5%;
}
.titBg-red {
    background: linear-gradient(to right, rgba(246, 109, 89, 0.3), rgba(246, 109, 89, 0));
}
.titBg-green {
    background: linear-gradient(to right, rgba(76, 217, 100, 0.3), rgba(76, 217, 100, 0));
}
.titBg-blue {
    background: linear-gradient(to right, rgba(0, 122, 255, 0.3), rgba(0, 122, 255, 0));
}
.amap-container {
    height: 80vh;
}
.amap-logo,
.amap-copyright {
    z-index: -1 !important;
}
.map-show-change-button {
    position: fixed;
    bottom: 100px;
    right: 10px;
}
</style>
